import { useTelegram } from "../hooks/useTelegram";
import "./main.css"
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RocketIcon } from './Vector.svg';

function HomePage() {
    const tg = useTelegram();
    useEffect(() => {
        document.body.style.backgroundColor = '#313B43';
    }, []);
        
    useEffect(() => {
        if (tg.BackButton) {
            // Показываем кнопку "Назад" в Telegram WebApp
            tg.BackButton.hide();
        }
    }, [tg]);

    return (
        <div className="container">
        <div className="header">
            <RocketIcon className="rocket-icon" />
            <h1>Just</h1>
            <p>Выберите желаемое действие.</p>
        </div>
        <div className="menu">
            <Link to='/crypto-details?exchangePath=CryptoBotToCryptoBot' className="menu-item" tabIndex={0}>
                <div className="icon exchange"></div>
                <span>Обмен крипты в CryptoBot</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToxRocket' className="menu-item" tabIndex={0}>
                <div className="icon trade"></div>
                <span>Обмен крипты в xRocket</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=CryptoBotToxRocket' className="menu-item" tabIndex={0}>
                <div className="icon tradeCBxRocket"></div>
                <span>Обмен с CryptoBot на xRocket</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToCryptoBot' className="menu-item" tabIndex={0}>
                <div className="icon tradexRocketCB"></div>
                <span>Обмен с xRocket на CryptoBot</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=CryptoBotToexternalWallet' className="menu-item" tabIndex={0}>
                <div className="icon tradeCBEW"></div>
                <span>Обмен с CryptoBot на внешний кошелек</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToexternalWallet' className="menu-item" tabIndex={0}>
                <div className="icon tradexRocketEW"></div>
                <span>Обмен с xRocket на внешний кошелек</span>
                <div className="arrow"></div>
            </Link>
        </div>
        </div>
    );
}

export default HomePage;
